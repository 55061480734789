@import "variables";
@import "backtotop";


$font-family-sans-serif: Open Sans, sans-serif;
$font-size-base: 1.25rem;  /* p= 20px */
$h1-font-size: $font-size-base * 2.3; /* 46px */
$h2-font-size: $font-size-base * 1.75; /* 35px */
$h3-font-size: $font-size-base * 1; /* 20px */

$lead-font-size: $font-size-base * 1.3; /* 26px */
$enable-responsive-font-sizes: true;
$line-height-base: 1.5;


$body-bg: white; 
$light: white !default;
$nav-link-padding-x: 0;
$danger: #F93822;

$input-btn-border-width: 0px;
$border-radius-lg: 0px;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .125),
    2: ($spacer * .25),
    3: $spacer,
    4: ($spacer * .75),
    5: ($spacer * 1.5),      
    6: ($spacer * 2),      
    7: ($spacer * 2.55),      
    8: ($spacer * 3.125),   
    9: ($spacer * 3.75),   
    10: ($spacer * 5.5),
    11: ($spacer * 8.5), 
    12: ($spacer * 10.5)
  ),
  $spacers
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

$table-cell-padding-x-sm: 0;

@import "../../node_modules/bootstrap/scss/bootstrap"; 


/* ----- Variables ---------------------------------------------- */

:root {
  /* Hauptfarben werden im Header bestimmt und aus dem CMS geladen  */
  --primary: #4580BE; 
  --gruen: #8DC148;
  --hellgruen: #F6F9ED;
  --schwarz: #494949;
}


/* ----- Allgemein ---------------------------------------------- */


.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
      width: 20%;
      float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
      width: 20%;
      float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
      width: 20%;
      float: left;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  margin-top: 0px;

  background-color: #fbfbfb;
  color: var(--schwarz);
}

.body-margin-top {
  margin-top: 75px;
}

.trennungen {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; 
}

::selection {
  background: var(--primary);
  color: white;
}

::-moz-selection {
  background: var(--primary);
  color: white;
}

/*
ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  display: inline;
}

.default ul {
  list-style: disc !important;
  padding-left: 1.5rem;
}
*/

.wrapper {
    max-width: 1900px;
    /*box-shadow: 0 0 50px 0 rgba(0,0,0,0.11);*/
    position: relative;
    background: 
      url(../images/background.svg)
      no-repeat;
    background-size: cover;
    background-size: contain;
    background-color: white;
}

.wrapper-unterseite {
  background: 
      url(../images/background-us.svg)
      no-repeat;
    background-size: cover;
    background-size: contain;
    background-color: white;
}

.container-fluid {
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.table.table-sm.table-borderless {
  --bs-table-bg: transparent;
  --bs-table-color: var(--schwarz);
}


hr {
  border-top: 2px solid white;
  opacity: 1;
}

.intro {
  margin-top: 220px;
}

a.anchor {
  display: block;
  position: relative;
  top: -25px;
  height: 0;
  width: 0;
  /* background-color: red;
  height: 5px;
  width: 200px; */
}

#home {
  top: -180px;
}




@media (max-width: 1399px) {
  .wrapper {
    max-width: 1600px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  body {
    font-size: 1.1rem;
  }

  .intro {
    margin-top: 170px;
  }
}

@media (max-width: 420px) {
  
  .intro {
    margin-top: 100px;
  }
}



/* ----- Typografie ---------------------------------------------- */

body {
  letter-spacing: 0;
  font-weight: 300;
}

p {
  margin-bottom: .5rem;
}

a {
  color: var(--primary);
  text-decoration: none;
}

p a,
.footer a,
a {
  transition: all 150ms ease-out;
}

.blau a {
  color: white;
  
}

.nav-link {
  font-weight: 900;
  font-size: 20px;
}

.link-text {
  font-weight: 900;
  font-size: $font-size-base * 1.3333;
}



p a:focus, 
p a:hover,
.blau a:focus,
.blau a:hover,
.footer a:focus,
.footer a:hover,
.nav-link a:hover,
.nav-link a:active,
.link-text:hover {
  text-decoration: underline;
}



.footer-socialmedia a {
  text-decoration: none;
}

.footer .footer-socialmedia a:focus,
.footer .footer-socialmedia a:hover {
  letter-spacing: 1.55px;
}

.text-schmal,
.text-schmal ul li,
.text-schmal a,
.default a {
  font-size: $font-size-base * 0.75; /* 24px */
}

h1, h2, h3, h4 {
  line-height: 1.3;
}

h1, h2, h3, h5 {
  color: var(--primary);
}

h4 {
  font-weight: 300;
}

.zitat {
  font-size: $font-size-base * 1.75; /* 42px */
  color: var(--hellblau);
  font-weight: 900;
}

.zitatabsender {
  font-size: $font-size-base * 1.25; /* 30px */
  color: black;
  font-weight: 300;
}


@media (max-width: 1600px) { 
  h1 {
    font-size: $h1-font-size * 0.75;
  }

  h2 {
    font-size: $h2-font-size * 0.75;
  }

  h3 {
    font-size: $h3-font-size * 0.75;
  }

  h4 {
    font-size: $h4-font-size * 0.75;
  }

  h5 {
    font-size: $h5-font-size * 0.75;
  }
  
  .lh-base {
    line-height: $line-height-base * 0.3;
  }

  body {
    font-size: $font-size-base * 0.75;
  }
  
  .lead {
    font-size: $h4-font-size * 0.75;
  }

  p a {
    font-size: $font-size-base * 1.625 * 0.55; /* 21,45px */
  }

  .link-text {
    font-size: $h4-font-size * 0.75;
  }
}


@media (max-width: 991px) { 
  h1 {
    font-size: $h1-font-size * 0.75;
  }

  h2 {
    font-size: $h2-font-size * 0.75;
  }

  h3 {
    font-size: $h3-font-size * 0.75;
  }

  h4 {
    font-size: $h4-font-size * 0.75;
  }

  h5 {
    font-size: $h5-font-size * 0.75;
  }
  
  .lh-base {
    line-height: $line-height-base * 0.3;
  }

  body {
    font-size: $font-size-base * 0.75;
  }
  
  .lead {
    font-size: $h4-font-size * 0.75;
  }

  p a {
    font-size: $font-size-base * 1.625 * 0.55; /* 21,45px */
  }

  .link-text {
    font-size: $h4-font-size * 0.75;
  }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  h1 {
    font-size: $h1-font-size * 0.45;
  }

  h2 {
    font-size: $h2-font-size * 0.65;
  }

  h3 {
    font-size: $h3-font-size * 0.75;
  }

  h4 {
    font-size: $h4-font-size * 0.65;
  }

  h5 {
    font-size: $h5-font-size * 0.75;
  }

  body {
    font-size: $font-size-base * 0.75;
  }

  .lh-base {
    line-height: $line-height-base * 1; 
  }

  .lead {
    font-size: $lead-font-size * 0.55;
    color: red !important;
  }

  p a,
  .zitatabsender {
    font-size: $font-size-base * 0.75; 
  }

  .zitat {
    font-size: $h2-font-size * 0.75;
  }

  .link-text {
    font-size: $h4-font-size * 0.65;
  }

}



/* ----- Layout ---------------------------------------------- */


.header {
  height: 875px;
  background-image: linear-gradient(257deg, #008ED9 0%, #52BDE5 61%, #1298DD 100%);
}


.fixed-icons {
  position: fixed;
  top: 300px;
  right: calc(50% - 1000px); /* Rechter Rand des Wrappers (max-width/2) */
  display: flex;
  flex-direction: column;
  align-items: center; /* Zentriert die Icons */
  gap: 10px; /* Abstand zwischen den Bildern */
  z-index: 1000; /* Damit das Element über anderen Inhalten liegt */
}

.fixed-icons .icon-fahne {
  height: auto;
  transition: transform 0.3s;
}

.fixed-icons .icon-fahne:hover {
  transform: scale(1.1); /* Vergrößerung bei Hover, optional */
}

.fixed-icons .telefon-icon {
  width: 60px; /* Größe des Telefon-Icons */
}

.fixed-icons .kalender-icon {
  width: 85px; /* Größe des Kalender-Icons */
}

@media (max-width: 575px) {
  .fixed-icons {
      display: none;
  }
}

@media (min-width: 575px) {
  .fixed-icons {
      display: flex;
  }
}

.team-bild {
  border-radius: 20px;
}

.logo-icon {
  z-index: 3;
  position: absolute;
  bottom: 3rem;
  right: 0;
}

.footer {
  background: 
    url(../images/background-footer.svg)
    no-repeat;
  background-size: cover;
  color: white;

  h2, h3, h4, a {
    color: white;
  }

  ul li {
    display: block;
  }

  .table.table-sm.table-borderless {
    --bs-table-color: #ffffff;
  }
  
}


.blau {
  padding: 5rem 0;
  color: white;
  background-image: linear-gradient(257deg, #008ED9 0%, #52BDE5 61%, #1298DD 100%);
  position: relative;

  h1, h2, h3, h4, h5 {
    color: white;
  }
}

.image-rechts {
  position: absolute;
  right: 0;
}

.image-header-1 {
  position: absolute;
  right: 22%;
  top: 75px;
  border-radius: 20px;
  max-width: 450px;
}

.image-header-2 {
  position: absolute;
  right: 10%;
  top: 250px;
  border-radius: 20px;
  max-width: 450px;
}

section {
  padding: 5rem 0;
}

.zitat {
  height: 300px;
  position: relative;
}



/* ----- Karte */

.karte {
  position: absolute;
  width: 900px;
  max-width: 100%;
  height: 615px;
  right: 2%;
}

#map {
  width: 100%;
  height: 100%;
}


.mapboxgl-popup {
  max-width: 600px !important;
}

.mapboxgl-popup-content {
  background: #fff0 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0) !important;
  padding: 20px 20px 25px !important;
}

.mapboxgl-popup-tip {
  border-top-color: var(--primary) !important;
}

.marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

#mapcontainer {
  position: absolute;
}



@media (max-width: 1585px) { 
  .image-header-1 {
    right: 20%;
  }
  
  .image-header-2 {
    right: 10%;
  }

  .karte {
    position: relative;
  }

}

@media (max-width: 1400px) { 
  .header-text {
    margin-top: 800px !important;
  }

  .image-header-1 {
    left: 15%;
    top: 0.5%;
  }
  
  .image-header-2 {
    right: 15%;
    top: 1.5%;
  }

  .image-rechts {
    position: relative;
  }

  .image-karriere-1 {
    position: initial;
  }

  .image-karriere-2 {
    position: initial;
  }

  .image-leistungen {
    position: relative;
  }

}


@media (max-width: 991px) { 
  .header-text {
    margin-top: 650px !important;
  }

  .image-header-1 {
    left: 20%;
    top: 0.5%;
    max-width: 40%;
    
  }
  
  .image-header-2 {
    right: 20%;
    top: 1.5%;
    max-width: 40%;
  }

  .image-rechts {
    position: relative;
  }

  .image-karriere-1 {
    position: initial;
  }
  
  .image-karriere-2 {
    position: initial;
  }

  .logo-icon {
    transform: scale(0.9);
  }

  .projekte-spaltenabstand {
    margin-top: 0px;
  }

  section {
    padding: 0 0;
  }

  #mapcontainer {
    position: relative;
  }
}


@media (max-width: 900px) { 
  .header-text {
    margin-top: 550px !important;
  }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .header-text {
    margin-top: 600px !important;
  }

  .image-header-1 {
    left: 10%;
    top: 0.5%;
    max-width: 60%;
    
  }
  
  .image-header-2 {
    right: 10%;
    top: 1.5%;
    max-width: 60%;
  }

  
  .karte {
    width: 100%;
  }

  .logo-icon {
    bottom: 0;
    right: -15%;
    transform: scale(0.5);
    padding-bottom: 3rem;
  }

}

@media (max-width: 525px) { 
  .header-text {
    margin-top: 500px !important;
  }
}

@media (max-width: 500px) { 
  .header-text {
    margin-top: 500px !important;
  }
}

@media (max-width: 400px) { 
  .header-text {
    margin-top: 450px !important;
  }
}

/* ----- Nav ------------------------------------------------- */

.navbar-brand {
  max-width: 75%;
}

.brand-text {
  max-height: 110px;
}

.navbar .navbar-nav {
  a {
    color: var(--primary);
    border-bottom: 2px solid transparent;
  }

  a:focus, a:hover, .active {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
    transition: all 100ms ease-in-out;
  }
}

.navbar {
  background-color: var(--hellgruen) !important;
  max-width: 1900px;
  height: 150px; /* war 266px; */
  transition: 0.2s;
}

body {
  padding-top: 150px;
}


.navbar-schmal {
  height: 152px; /*  war 182px; */
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9;
  display: none;
  top: 0;
  background-color: var(--primary);

  .navbar-overlay-container {
    height: 100vh;
    background: none;
  }

  .nav-link {
    color: white;
    font-size: 24px;
  }

  a, a.visited {
    color: white;
    
  }

  a.hover {
    text-decoration: underline;
  }

  .navbar-nav .nav-link.active {
    color: white;
  }
}

.icon--transparent span {
  background-color: var(--primary);
}

.icon {
  border: 0;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 10;
  transition: all 200ms ease-in-out;
  background-color: inherit;
  padding-left: 0;
}

.icon span {
  display: block;
  height: 3px;
  width: 63px;
  border-radius: 2px;
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.icon span:first-child {
  top: 28px;
}

.icon span:nth-child(2) {
  top: 37px;
}

.icon span:last-child {
  top: 46px;
}

.icon--active span {
  
  
}

.icon--active span:first-child {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 37px;
}

.icon--active span:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  top: 37px;
}

.icon--active span:nth-child(2) {
  opacity: 0;
}

.icon--transparent {
  
}

.icon--transparent.icon--active {
  
}

.icon-transition {
  -webkit-transition: -webkit-transform 0.3s;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}



@media (max-width: 1399px) {
  
  .navbar {
    height: 166px;
    max-width: 1600px;
  }

  body {
    padding-top: 166px;
  }
  
  .brand-circle {
    height: 124px;
  }

  .intro {
    margin-top: 166px;
  }
}


@media (max-width: 991px) { 
  .navbar .logo, 
  .navbar-home .logo  {
    transform: scale(0.9); 
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 610px) {
  
  .brand-text {
    transform: scale(1); /* scales it to half its size */
    transform-origin: center left;
  }

  .navbar {
    height: 142px;
  }

  body {
    padding-top: 142px;
  }

  .brand-circle {
    height: 100px;
  }

  .intro {
    margin-top: 142px;
  }

  .icon {

    span {
      left: 30%;
      height: 2px;
      width: 43px;
    }
  }

  .navbar-home {
    top: 2rem;
  }
}

@media (max-width: 425px) {
  
  .brand-text {
    transform: scale(1); 
  }

  .brand-circle {
    height: 70px;
  }

  .navbar {
    height: 100px;
  }

  body {
    padding-top: 100px;
  }

  .intro {
    margin-top: 100px;
  }

}

@media (max-width: 374px) {
  
  .brand-text {
    transform: scale(0.5); /* scales it to half its size */
    transform-origin: center left;
  }

  .brand-circle {
    height: 70px;
  }

}








/* ----- Animation --------------------------------------------- */
/*=== Trigger  ===*/
.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/
.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animation_delay {
  
}

body {
  animation: fadeIn 1.0s; 
}

